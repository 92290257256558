<template>
	<div class="app-container" style="overflow-x: visible !important;">
		<el-container style="border: 1px solid #eee;" class="mt-3 pt-3">
			<div class="col-12">
				<el-main class="row">
					<el-tree :data="treeList" :load="loadTree" lazy node-key="id" :expand-on-click-node="false" :render-content="renderContent" :props="defaultProps"></el-tree>
				</el-main>
			</div>
		</el-container>

		<el-tooltip placement="top" :content="$t('general.back_to_top')">
			<backtotop :visibility-height="300" :back-position="50" transition-name="fade" />
		</el-tooltip>
	</div>	
</template>

<script>

import {getLocalStorage} from '@/system/store/localstorage';
import {postMethod} from '@/system/model/post/post';
import {getDAES, getEAES} from '@/system/library/security';
import backtotop from '@/components/backtotop';

export default{
	components: {backtotop},
	data (){
		return{
			loading: false,
			postData:{
				data: '',
				language: ''
			},
			postForm:{
				id: ''
			},
			permissionList: [],
			treeList: [],
			defaultProps:{
				children: 'children',
				label: 'label'
			},permission:{
				index: 'UREVUC',
				loadMore: '8C91WR'
			}
		}
	},methods: {
		initial(){
			if(this.permissionList.includes(this.permission.index)){
				this.treeList = [];
				this.loading = true;
				this.postData.data = getEAES(JSON.stringify(this.searchData));
				var self = this;
				var result = postMethod('network/admin/index',this.postData);
				result.then(function(value){
					var data = JSON.parse(getDAES(value.data));
					if(value.valid && data){
						
						self.treeList.push(data);
					}
					
					self.loading = false;
				});
			}
		},renderContent(h, { node, data }) {
			return (<span class="custom-tree-node" on-click={ () => this.infoDetail(node, data) }><span>{node.label}</span></span>);
		},loadTree(node, resolve){
			if(this.permissionList.includes(this.permission.loadMore)){
				if(node != 'undefined'){
					this.postForm.id = node.data.id;
				
					if(node.childNodes.length <= 0){
						this.loading = true;
						
						this.postData.data = getEAES(JSON.stringify(this.postForm));
						var self = this;
						
						var result = postMethod('network/admin/loadMore',this.postData);
						result.then(function(value){
							var data = JSON.parse(getDAES(value.data));
							if(value.valid){
								if(data.length >= 1){
									resolve(data);
								}else{
									resolve([]);
								}
							}else{
								resolve([]);
							}
							self.loading = false;
						});
					}else{
						resolve([]);
					}
				}
			}else{
				resolve([]);
			}
		}
	}, created: function(){
		var currentLang = (getLocalStorage('currentLang')) ? getLocalStorage('currentLang') : 'en';
		this.permissionList = JSON.parse(getLocalStorage('permissionList'));
		this.postData.language = currentLang;
		this.initial();
	}
}

</script>